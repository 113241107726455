.site-header {
    background-color: #1E285A;
    color: white;

    .site-title,
    .page-link,
    a {
        color: white;
    }

    .site-title {
        align-items: center;
        display: flex;
    }

    .menu-icon > svg > path, {
        fill: white;
    }
}

.site-footer {
    padding-bottom: 0;

    .site-footer-inner {
        font-size: 75%;

        a {
            text-decoration: none;
            color: $text-color;
        }
    }
}

.section {
    @media (min-width: $on-palm) {
        display: block !important;
        &:first-of-type {
            padding-top: 2em;
        }
    }
}

.section-center .section-text {
    display: grid;
}

.section .section-text p {
    &:first-of-type {
        margin-top: unset;
    }

    margin-bottom: 2em;
}

.section-text {
    .carousel {
        margin-top: -1em;

        @media (min-width: $on-laptop) {
            .carousel-viewport {
                display: inline-flex;

                .carousel-slide {
                    display: flex;
                    flex-direction: column;
                    padding: 1em;
                    width: 100%;

                    .screenshot {
                        aspect-ratio: auto;
                        max-height: 35em;
                    }
                }
            }
            .carousel-navigation {
                display: none;
            }
        }

        .carousel-slide {
            img {
                display: unset;
            }

            figcaption {
                margin-top: 0.5em;
            }

            .screenshot {
                aspect-ratio: auto;
            }
        }

        @media (max-width: $on-palm) {

            .carousel-navigation {
                display: inline-flex;
                align-items: center;
                margin: 0.25em;
                justify-content: center;

                a {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.5em;
                    height: 1.5em;
                    background: $text-color;
                    color: $background-color;
                    text-decoration: none;
                    border-radius: 50%;

                    &:not(:first-of-type) {
                        margin-left: 0.1em;
                    }

                    &:not(:last-of-type) {
                        margin-right: 0.1em;
                    }

                }
            }
            .carousel-viewport {
                display: flex;
                overflow-x: auto;
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;
                --webkit-overflow-scrolling: touch;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: black;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                .carousel-slide {
                    scroll-padding-top: 2em;
                    scroll-snap-align: start;
                    flex-shrink: 0;
                    transform-origin: center center;
                    transform: scale(1);
                    transition: transform 0.5s;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    flex-basis: 100%;

                    .screenshot {
                        aspect-ratio: auto;
                        width: 80%;
                    }
                }
            }

        }
    }

    .section-intro {
        display: contents;

        .intro {
            display: flex;
            align-items: center;
            align-content: space-between;
            justify-content: space-around;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 2em;
            padding-top: 1em;

            @media (max-width: $on-palm) {
                padding-top: 3em;
            }

            .icon {
                aspect-ratio: 1/1;
                height: 10em;
                width: 10em;

                @media (max-width: $on-palm) {
                    max-height: 5em;
                    max-width: 5em;
                }
            }

            .between {
                color: $snail-brand-color;
                font-size: 3em;
                padding-left: 0.5em;
                padding-right: 0.5em;

                @media (max-width: $on-palm) {
                    font-size: 1.5em;
                    padding-left: 0.5em;
                    padding-right: 0.5em;
                }
            }

            .result {
                background: -webkit-linear-gradient(250deg, $snail-brand-color, $snail-brand-color, $turquoise, $turquoise);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 8em;
                alignment-baseline: center;
                @media (max-width: $on-palm) {
                    font-size: 4em;
                }
            }
        }

        .app-store-download {
            margin-left: auto;
            margin-right: auto;
            max-height: 4em;
        }
    }

    .image-grid {

        img {
            all: unset;
        }

        @media (min-width: $on-laptop) {
            display: inline-flex;
            gap: 1em;
            align-content: space-between;
            margin-left: auto;
            margin-right: auto;
        }
        @media (max-width: $on-palm) {
            display: grid;
            gap: 0.5em;
            grid-auto-flow: dense;
            grid-template-columns: repeat(2, 1fr);
        }

        .image {
            display: flex;
            flex-direction: column;

            &.integration {
                aspect-ratio: auto;
                display: inline-flex;
                flex: 1 1 auto;
                justify-content: space-between;

                img {
                    height: 3em;
                    max-height: 3em;
                    padding-left: 2em;
                    padding-right: 2em;
                }
            }

        }

        .widget-small {
            img {
                aspect-ratio: 1;
                height: 125px;
            }
        }

        .widget-medium {
            @media (max-width: $on-palm) {
                grid-column-end: span 2;
            }

            img {
                height: 125px;
            }
        }
    }

    .features {
        align-content: space-evenly;
        height: 100%;

        &.feature-grid {
            @media (min-width: $on-laptop) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 2em;
            }
            @media (max-width: $on-palm) {
                display: block;
                grid-template-columns: repeat(2, 1fr);
                gap: 0.8em;
            }
            grid-auto-flow: row;
        }

        &:not(.feature-grid) {
            .feature-item {
                display: grid;
                grid-auto-flow: row;
                text-align: center;
                @media (min-width: $on-palm) {
                    max-width: 300px;
                }
                @media (max-width: $on-palm) {
                    width: 100%;
                }
            }
        }

        .feature-item {
            &:nth-of-type(odd) {
                i {
                    background: -webkit-linear-gradient($snail-brand-color, $turquoise);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            &:nth-of-type(even) {
                i {
                    background: -webkit-linear-gradient($turquoise, $snail-brand-color);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .feature-icon-icon {
                background: $site-header;
                width: fit-content;
                height: fit-content;
                border-radius: 1em;
                padding: 0.75em;
                display: flex;
                aspect-ratio: 1/1;
                margin: auto;
                align-content: center;
                align-items: center;

                i {
                    font-size: 4em;
                }
            }

            h3 {
                padding-top: 1em;
            }

            p {
                margin-top: 0 !important;
            }
        }
    }
}

.collection-grid-row {
    display: flex;
    gap: 0.5em;
    justify-content: space-evenly;
    margin-top: 1em;
    flex-wrap: wrap;

    .collection-grid {
        flex: 1 0 20%;

        @media (max-width: $on-palm) {
            flex: 0 0 40%;
        }

        .grid-icon {
            font-size: 3em;
            background: $site-header;
            width: fit-content;
            height: fit-content;
            border-radius: 0.3em;
            padding: 0.25em;
            display: flex;
            aspect-ratio: 1/1;
            margin: auto;
            align-content: center;
            align-items: center;

            a {
                color: var(--secondary-color);
            }
        }

        h3 {
            padding-top: 0.5em;
            font-size: 1.5em;
        }

        p {
            margin-top: 0 !important;
        }

        &.pink {
            i {
                background: -webkit-linear-gradient($snail-brand-color, $turquoise);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &.blue {
            i {
                background: -webkit-linear-gradient($turquoise, $snail-brand-color);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}

.section-text > .landing-centered,
.multiple-images {
    display: flex;
    gap: 1em;
    justify-content: space-between;

    img {
        max-height: 350px;
        @media (max-width: $on-palm) {
            padding-bottom: 1em;
        }
    }
}

.docs-page {
    .documentation {
        .widget-image {
            img {
                aspect-ratio: auto;
                max-height: 200px;
            }

            float: right;
            padding-left: 1em;
            padding-bottom: 1em;
            max-width: 50%;
        }

        .screenshot {
            text-align: center;

            img {
                all: revert;
                max-width: 75%;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        img:not(.shortcuts-action),
        object:not(.inline),
        img:not(.widget-image) {
            object-fit: contain;
        }
    }
}

ol.breadcrumb {
    margin-left: 0;
}

.guide {
    img {
        float: left;
        margin: 1em;
        max-width: 250px;
        object-fit: contain;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        display: block;
    }
}

.card-columns {
    align-items: start;
    display: grid;
    gap: 1em;
    @media (min-width: $on-laptop) {
        grid: auto-flow / minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr);
    }
    @media (max-width: $on-palm) {
        grid: auto-flow / 1fr;
        img {
            max-height: 500px !important;
            object-fit: contain;
        }
    }
}

.lang-choice {
    font-size: 0.75em;

    .nav-item {
        --bs-nav-link-padding-x: 0.5em;
        --bs-nav-link-padding-y: 0.25em;

        .active {
            background-color: $snail-brand-color;
        }
    }
}

pre,
.highlight {
    .rouge-table {
        border: none;
        margin-bottom: revert;

        table td,
        td.code,
        td.gutter {
            border: none;
            padding: revert;
            padding-right: 1em;

            pre {
                border: none;
                margin-bottom: revert;
                padding: revert;

                &.lineno {
                    color: lighten($lm-table-text-color, 40%);

                    @media (prefers-color-scheme: dark) {
                        color: darken($dm-table-text-color, 40%);
                    }
                }
            }
        }
    }
}

//Customize the Bootstrap Colours
$theme-colors: (
        "primary": $brand-color,
        "secondary": $secondary-color
);

:root {
    --bs-black: #000000;
    --bs-black-rgb: var(--minima-background-color);
    --bs-blue: #0D6EFD;
    --bs-body-bg: var(--minima-background-color);
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-body-color: var(--minima-text-color);
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-border-color: #DEE2E6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-pill: 50rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-style: solid;
    --bs-border-width: 1px;
    --bs-code-color: #D63384;
    --bs-cyan: #0DCAF0;
    --bs-danger: #DC3545;
    --bs-danger-rgb: 220, 53, 69;
    --bs-dark: var(--minima-background-color);
    --bs-dark-rgb: var(--minima-background-color);
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-gray: #6C757D;
    --bs-gray-100: #F8F9FA;
    --bs-gray-200: #E9ECEF;
    --bs-gray-300: #DEE2E6;
    --bs-gray-400: #CED4DA;
    --bs-gray-500: #ADB5BD;
    --bs-gray-600: #6C757D;
    --bs-gray-700: #495057;
    --bs-gray-800: #343A40;
    --bs-gray-900: #212529;
    --bs-gray-dark: #343A40;
    --bs-green: #198754;
    --bs-highlight-bg: #FFF3CD;
    --bs-indigo: #6610F2;
    --bs-info: #0DCAF0;
    --bs-info-rgb: 13, 202, 240;
    --bs-light: var(--minima-text-color);
    --bs-light-rgb: var(--minima-text-color);
    --bs-link-color: #0D6EFD;
    --bs-link-hover-color: #0A58CA;
    --bs-orange: #FD7E14;
    --bs-pink: #D63384;
    --bs-primary: #{$snail-brand-color};
    --bs-primary-rgb: 13, 110, 253;
    --bs-purple: #6F42C1;
    --bs-red: #DC3545;
    --bs-secondary: #{$secondary-color};
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success: #198754;
    --bs-success-rgb: 25, 135, 84;
    --bs-teal: #20C997;
    --bs-warning: #FFC107;
    --bs-warning-rgb: 255, 193, 7;
    --bs-white: #FFFFFF;
    --bs-white-rgb: var(--minima-text-color);
    --bs-yellow: #FFC107;
}

$prefix: "bs-";
$spacer: 1rem !default;
$border-width: 1px !default;
$border-radius: .375rem !default;
$grid-gutter-width: 1.5rem !default;
$card-spacer-y: $spacer !default;
$card-spacer-x: $spacer !default;
$card-title-spacer-y: $spacer * .5 !default;
$card-border-width: $border-width !default;
$card-border-color: var(--secondary-color) !default;
$card-border-radius: $border-radius !default;
$card-box-shadow: null !default;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y: $card-spacer-y * .5 !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-bg: var(--secondary-color) !default;
$card-cap-color: white !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: var(--minima-background-color) !default;
$card-img-overlay-padding: $spacer !default;
$card-group-margin: $grid-gutter-width * .5 !default;

.card {
    // scss-docs-start card-css-vars
    --#{$prefix}card-spacer-y: #{$card-spacer-y};
    --#{$prefix}card-spacer-x: #{$card-spacer-x};
    --#{$prefix}card-title-spacer-y: #{$card-title-spacer-y};
    --#{$prefix}card-border-width: #{$card-border-width};
    --#{$prefix}card-border-color: #{$card-border-color};
    --#{$prefix}card-border-radius: #{$card-border-radius};
    --#{$prefix}card-box-shadow: #{$card-box-shadow};
    --#{$prefix}card-inner-border-radius: #{$card-inner-border-radius};
    --#{$prefix}card-cap-padding-y: #{$card-cap-padding-y};
    --#{$prefix}card-cap-padding-x: #{$card-cap-padding-x};
    --#{$prefix}card-cap-bg: #{$card-cap-bg};
    --#{$prefix}card-cap-color: #{$card-cap-color};
    --#{$prefix}card-height: #{$card-height};
    --#{$prefix}card-color: #{$card-color};
    --#{$prefix}card-bg: #{$card-bg};
    --#{$prefix}card-img-overlay-padding: #{$card-img-overlay-padding};
    --#{$prefix}card-group-margin: #{$card-group-margin};
    // scss-docs-end card-css-vars

    background-clip: border-box;
    background-color: var(--#{$prefix}card-bg);
    border: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    height: var(--#{$prefix}card-height);
    min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
    position: relative;
    word-wrap: break-word;

    > hr {
        margin-left: 0;
        margin-right: 0;
    }

    > .list-group {
        border-bottom: inherit;
        border-top: inherit;

        &:first-child {
            border-top-width: 0;
        }

        &:last-child {
            border-bottom-width: 0;
        }
    }

    // Due to specificity of the above selector (`.card > .list-group`), we must
    // use a child selector here to prevent double borders.
    > .card-header + .list-group,
    > .list-group + .card-footer {
        border-top: 0;
    }

    .card-body {
        // Enable `flex-grow: 1` for decks and groups so that card blocks take up
        // as much space as possible, ensuring footers are aligned to the bottom.
        color: var(--#{$prefix}card-color);
        flex: 1 1 auto;
        padding: var(--#{$prefix}card-spacer-y) var(--#{$prefix}card-spacer-x);
    }

    .card-title {
        margin-bottom: var(--#{$prefix}card-title-spacer-y);
    }

    .card-subtitle {
        margin-bottom: 0;
        margin-top: calc(-.5 * var(--#{$prefix}card-title-spacer-y)); // stylelint-disable-line function-disallowed-list
    }

    .card-text:last-child {
        margin-bottom: 0;
    }

    .card-link {
        &:hover {
            text-decoration: none;
        }

        + .card-link {
            margin-left: var(--#{$prefix}card-spacer-x);
        }
    }

    //
    // Optional textual caps
    //

    .card-header {
        background-color: var(--#{$prefix}card-cap-bg);
        border-bottom: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);
        color: var(--#{$prefix}card-cap-color);
        margin-bottom: 0; // Removes the default margin-bottom of <hN>
        padding: var(--#{$prefix}card-cap-padding-y) var(--#{$prefix}card-cap-padding-x);

        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 0;

            a.card-title {
                color: inherit;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .card-footer {
        background-color: var(--#{$prefix}card-cap-bg);
        border-top: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);
        color: var(--#{$prefix}card-cap-color);
        padding: var(--#{$prefix}card-cap-padding-y) var(--#{$prefix}card-cap-padding-x);
    }


    //
    // Header navs
    //

    .card-header-tabs {
        border-bottom: 0;
        margin-bottom: calc(-1 * var(--#{$prefix}card-cap-padding-y)); // stylelint-disable-line function-disallowed-list
        margin-left: calc(-.5 * var(--#{$prefix}card-cap-padding-x)); // stylelint-disable-line function-disallowed-list
        margin-right: calc(-.5 * var(--#{$prefix}card-cap-padding-x)); // stylelint-disable-line function-disallowed-list

        .nav-link.active {
            background-color: var(--#{$prefix}card-bg);
            border-bottom-color: var(--#{$prefix}card-bg);
        }
    }

    .card-header-pills {
        margin-left: calc(-.5 * var(--#{$prefix}card-cap-padding-x)); // stylelint-disable-line function-disallowed-list
        margin-right: calc(-.5 * var(--#{$prefix}card-cap-padding-x)); // stylelint-disable-line function-disallowed-list
    }

    // Card image
    .card-img-overlay {
        bottom: 0;
        left: 0;
        padding: var(--#{$prefix}card-img-overlay-padding);
        position: absolute;
        right: 0;
        top: 0;
    }

    .card-img,
    .card-img-top,
    .card-img-bottom {
        width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
    }
}


//
// Card groups
//

.card-group {
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
        margin-bottom: var(--#{$prefix}card-group-margin);
    }
}

.breadcrumb a,
.post-link {
    &:hover {
        text-decoration: none;
    }
}

.float-center {
    margin: 0 auto;
}

.documentation,
.post-tags {
    &.post-excerpt {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.badge {
    color: white;
    font-size: 65%;
    margin: 0.2em 0.5em 0.5em;
    vertical-align: middle;

    @media (prefers-color-scheme: dark) {
        &.category-badge,
        &.release-badge {
            color: black;
        }
    }

    a {
        color: white;
        text-decoration: none;
    }

    &:not(:last-of-type) {
        margin-right: 1em;
    }

    &.category-badge {
        background-color: var(--primary-color);
    }

    &.release-badge {
        background-color: var(--secondary-color);
    }

    &.tag-badge {
        background-color: var(--tag-colour);
    }
}

h1,
h2,
h3,
h4,
.documentation {
    .badge {
        font-size: 0.4em !important;
    }
}

.shortcuts-action-icon {
    font-size: 4em;

    a {
        color: var(--secondary-color);
    }
}

.shortcuts-action {
    .icon > i {
        padding-right: 0.5em;

        &:first-child {
            color: $snail-brand-color;
        }

        &:last-child {
            color: $secondary-color;
        }
    }

    .shortcuts-table {
        tr > td:first-child {
            width: 250px;
        }

        td {
            > ul, ol, dl {
                margin-bottom: initial;
                margin-left: initial;
            }
        }
    }
}

.site-footer {
    opacity: 70%;
}

.docs-page {
    .sidenav {
        @media (min-width: $on-palm) {
            align-self: start;
            position: sticky;
            top: 60px;
        }
    }
}

.post-list {

    hr.post-separator {
        clear: both;
        opacity: 0;
        @media (min-width: $on-palm) {
            padding-top: 1em;
        }
    }

    & > li {
        clear: both;


        picture,
        p > img,
        p > object {
            @media (min-width: $on-palm) {
                margin: 0;
                object-fit: contain;
                width: 350px;

                &:nth-child(even) {
                    float: right;
                    padding-left: 1em;
                }

                &:nth-child(odd) {
                    float: left;
                    padding-right: 1em;
                }
            }
        }

        .post {
            display: grid;
            grid-auto-flow: row;

            @media (min-width: $on-palm) {
                .post-tags {
                    grid-row: 2;
                }
            }
        }
    }
}

.post-content {
    all: revert;

    picture,
    p > img,
    p > object {
        @media (min-width: $on-palm) {
            margin: 0;
            object-fit: contain;
            width: 350px;
        }
    }

    picture:nth-of-type(odd),
    p > img:nth-child(odd),
    p > object:nth-child(odd) {
        float: right;
        padding-left: 1em;
    }

    picture:nth-of-type(even),
    p > img:nth-child(even),
    p > object:nth-child(even) {
        float: left;
        padding-right: 1em;
    }


}

.footnotes {
    border-top: solid 0.25px var(--minima-text-color);
    clear: both;
    font-size: 75%;
    margin-top: 2em;
    padding-top: 1em;
}

.shortcuts-list {
    h2 {
        &:not(:first-of-type) {
            padding-top: 1.5em;
        }
    }
}

.mobile-only {
    @media (min-width: $on-palm) {
        display: none;
    }
}

.desktop-only {
    @media (max-width: $on-palm) {
        display: none;
    }
}

.promo-landing {
    @media (max-width: $on-palm) {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 33% 33% 33%;
    }
    @media (min-width: $on-palm) {
        display: grid;
        gap: 0.5em;
        grid-auto-flow: column;
        grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: $on-palm) {
        .widget {
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    @media (max-width: $on-palm) {
        .f1_widget {
            grid-column-start: 1;
            grid-column-end: 1;
            grid-row-start: 1;
            grid-row-end: 1;
        }
        .vacation_widget {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 1;
        }
        .lock_widget {
            grid-column-start: 3;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 1;

            img {
                margin-right: auto;
            }
        }

        .overview-text {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 2;
        }
    }
    @media (min-width: $on-palm) {

        .f1_widget {
            grid-column-start: 1;
            grid-column-end: 1;
            grid-row-start: 1;
            grid-row-end: 1;
        }
        .vacation_widget {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 1;
        }
        .lock_widget {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 2;
        }

        .overview-text {
            grid-column-start: 3;
            grid-column-end: 6;
            grid-row-start: 1;
            grid-row-end: 1;
        }
    }

    .features {
        @media (max-width: $on-palm) {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 3;
            grid-row-end: 3;
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 1em;
        }
        @media (min-width: $on-palm) {
            grid-column-start: 2;
            grid-column-end: 6;
            grid-row-start: 2;
            grid-row-end: 2;
            justify-content: space-between;
            display: flex;
            gap: 1.5em;
        }

        .feature {

            img {
                max-width: 1.2em;
                aspect-ratio: 1;
            }
        }
    }
}

.app-store {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    .app-icon {
        max-height: 80px;
    }
}

.reset-all {
    all: unset;
}

.shortcuts-action-block {
    max-width: 25rem;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    border: 2px solid $border-color-02;
    border-radius: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    margin-bottom: 2em;
    @media (prefers-color-scheme: light) {
        box-shadow: 0 10px 15px $border-color-02;
    }
    @media (prefers-color-scheme: dark) {
        background-color: #1C1C1E;
        border-width: 1px;
    }

    .shortcuts-action-row {
        &:not(:first-child) {
            border-top: 0.7px solid $border-color-01;
        }

        &:not(:last-child) {
            border-bottom: 0.7px solid $border-color-01;
        }

        padding: 0.2em 0.5em;

        .shortcuts-variable {
            background: var(--shortcuts-blue-background);
            padding: 0.1em 0.2em;
            border-radius: 0.25em;
            color: var(--shortcuts-blue);
            display: inline-flex;
            align-items: center;

            .variable-icon {
                display: inline;
                max-height: 1em;
                padding-right: 0.2em;
            }
        }
    }

    .shortcuts-action-header {
        font-weight: bold;

        .shortcut-action-icon {
            max-height: 1.2rem;
            max-width: 1.2rem;
            height: 1.2rem;
            width: 1.2rem;
            display: inline-block !important;
            vertical-align: text-top;
            object-fit: contain;
        }
    }

    .shortcuts-action-extended {
        border-top-width: 2px !important;
    }

    .shortcuts-action-extended-detail {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;

        .shortcuts-action-extended-title {
            text-align: start;
        }

        .shortcuts-action-extended-value {
            text-align: end;

            &.shortcuts-action-extended-detail-select,
            &.shortcuts-action-extended-detail-toggle-on {
                color: var(--shortcuts-blue);
            }

            &.shortcuts-action-extended-detail-text .shortcuts-action-placeholder,
            &.shortcuts-action-extended-detail-toggle-off {
                opacity: 0.5;
                @media (prefers-color-scheme: dark) {
                    opacity: 0.6;
                }
            }
        }
    }
}

.floating-picture {
    @media (min-width: $on-palm) {
        margin: 0;
        object-fit: contain;
        width: 350px;

        &:nth-child(even) {
            float: right;
            padding-left: 1em;
        }

        &:nth-child(odd) {
            float: left;
            padding-right: 1em;
        }
    }
}

table {
    @media (max-width: $on-palm) {
        display: inline-table;
    }
}

a {
    &.discord-button {
        background-color: #5865f2;
        border: none;
        border-radius: 28px;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 20px;
        margin-left: auto;
        margin-right: auto;
    }
}

.center-text {
    text-align: center;
}