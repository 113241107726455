$snail-brand-color: #E775C2;
$lm-link-base-color: darken($snail-brand-color, 25%);
$dm-link-base-color: brand-color;
$turquoise: rgba(84,182,212,.95);
$site-header: #1E285A;
:root {
	--primary-color: #{$snail-brand-color};
	--secondary-color: #{darken($turquoise, 25%)};
	--tag-colour: #{$site-header};
	--minima-link-base-color: #{$lm-link-base-color};
	--minima-link-visited-color: #{$lm-link-base-color};
	--minima-link-hover-color: #{$lm-link-base-color};
	--shortcuts-blue: #3677F5;
	--shortcuts-blue-background: #F1F5FE;

	@media (prefers-color-scheme: dark) {
		--secondary-color: #{$turquoise};
		--minima-link-base-color: #{$snail-brand-color};
		--minima-link-visited-color: #{$snail-brand-color};
		--minima-link-hover-color: #{$snail-brand-color};
		--shortcuts-blue: #3B82F8;
		--shortcuts-blue-background: #1E222B;
	}
}

$secondary-color: var(--secondary-color);